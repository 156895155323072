// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import LinkedAccounts from "../../blocks/LinkedAccounts/src/LinkedAccounts";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Notifications from "../../blocks/notifications/src/Notifications";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import DataStorage from "../../blocks/DataStorage/src/DataStorage";
import CfVideoEmbeddingVimeo6 from "../../blocks/CfVideoEmbeddingVimeo6/src/CfVideoEmbeddingVimeo6";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Scoring from "../../blocks/Scoring/src/Scoring";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SalesforceIntegration from "../../blocks/SalesforceIntegration/src/SalesforceIntegration";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";



const routeMap = {
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
LinkedAccounts:{
 component:LinkedAccounts,
path:"/LinkedAccounts"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
CfVideoEmbeddingVimeo6:{
 component:CfVideoEmbeddingVimeo6,
path:"/CfVideoEmbeddingVimeo6"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SalesforceIntegration:{
 component:SalesforceIntegration,
path:"/SalesforceIntegration"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;